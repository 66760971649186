<template>
  <div>
    <v-app-bar
      app
      dense
      style="height: 80px; background-color: #fff"
      fixed
      sticky
    >
      <v-row class="responsive-item-app-bar">
        <v-col cols="3" md="4" sm="3" class="">
          <v-app-bar-nav-icon @click="showContentToggle()"></v-app-bar-nav-icon>
          <!-- <toggle-button
            :v-model="$i18n.locale"
            :labels="{ checked: 'ENG', unchecked: 'TH' }"
            :color="{ checked: '#718096', unchecked: '#4ca3ad' }"
            :switch-color="{ checked: '#2c2c2c', unchecked: '#008080' }"
            @change="switchLanguage()"
          /> -->
        </v-col>
        <v-col cols="5" md="4" sm="5" class="mx-auto mt-n2">
          <v-img
            width="200px"
            height="60px"
            src="../assets/logo/Logo2.jpg"
            alt="logoDragon"
            class="mx-auto"
            style="max-width: 100%"
          />
        </v-col>
        <v-col cols="3" md="4" sm="3" class="text-right" style="z-index: 9999">
          <!-- Use text-right to align content to the right -->
          <v-row align="center" justify="end" class="mx-0">
            <v-col cols="auto">
              <v-menu open-on-hover top offset-y @click.stop="">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark v-bind="attrs" v-on="on" icon>
                    <img
                      src="../assets/logo/icon_profile.png"
                      alt="Profile"
                      class="responsive-img"
                    />
                  </v-btn>
                </template>

                <v-list v-if="Object.keys(userData).length !== 0">
                  <v-list-item
                    v-for="(item, index) in itemsLogin"
                    :key="index"
                    :class="{ 'hovered-item': hoveredItem === index }"
                    @mouseover="setHoveredItem(index)"
                    @mouseleave="resetHoveredItem"
                    @click="handleItemClick(item)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-else>
                  <v-list-item
                    v-for="(item, index) in itemsNoLogin"
                    :key="index"
                    :class="{ 'hovered-item': hoveredItem === index }"
                    @mouseover="setHoveredItem(index)"
                    @mouseleave="resetHoveredItem"
                    @click="handleItemClick(item)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mt-n8 text-right"
          >{{ this.userData.user ? this.userData.user.firstName : "" }}
          {{ this.userData.user ? this.userData.user.lastName : "" }}</v-col
        >
      </v-row>
    </v-app-bar>

    <v-card
      v-if="showContent"
      class="rounded-0"
      :style="{ top: cardTop + 'px' }"
      style="z-index: 9999"
      ref="stickyCard"
    >
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="selected-item">
          <v-list-item @click="goToAnotherPage('ตู้เซฟ')">
            <v-list-item-title style="font-size: 18px"
              ><v-icon color="black">mdi-package</v-icon>&nbsp;
              {{ $t("appbar.mysafe") }}</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click="goToAnotherPage('ตะกร้า')">
            <v-list-item-title style="font-size: 18px"
              ><v-icon color="black">mdi-basket</v-icon> &nbsp;{{
                $t("appbar.basket")
              }}</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click="goToAnotherPage('ติดต่อเจ้าหน้าที่')">
            <v-list-item-title style="font-size: 18px"
              ><v-icon color="black">mdi-headset</v-icon> &nbsp;{{
                $t("appbar.contactus")
              }}</v-list-item-title
            >
          </v-list-item>

          <!-- <v-list-item @click="goToAnotherPage('คุกกี้')">
            <v-list-item-title style="font-size: 18px"
              ><v-icon color="black">mdi-cookie</v-icon> &nbsp;{{
                $t("appbar.cookies")
              }}</v-list-item-title
            >
          </v-list-item> -->

          <v-list-item @click="goToAnotherPage('นโยบายและเงื่อนไข')">
            <v-list-item-title style="font-size: 18px"
              ><v-icon color="black">mdi-file-document</v-icon> &nbsp;{{
                $t("appbar.policy")
              }}</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click="goToAnotherPage('ความเป็นส่วนตัว')">
            <v-list-item-title style="font-size: 18px"
              ><v-icon color="black">mdi-shield-lock</v-icon> &nbsp;{{
                $t("appbar.privacy")
              }}</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click="goToAnotherPage('เกี่ยวกับเรา')">
            <v-list-item-title style="font-size: 18px"
              ><v-icon color="black">mdi-alert-circle</v-icon> &nbsp;{{
                $t("appbar.aboutus")
              }}</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            @click="goToAnotherPage('ออกจากระบบ')"
            v-if="Object.keys(userData).length !== 0"
          >
            <v-list-item-title style="font-size: 18px"
              ><v-icon color="black">mdi-logout</v-icon> &nbsp;{{
                $t("appbar.logout")
              }}</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>

    <DialogLogin :dialog="dialogLogin" @closeDialogLogin="closeDialogLogin" />
  </div>
</template>

<script>
import { Encode, Decode } from "@/services";
import DialogLogin from "./Dialog/DialogLogin.vue";

export default {
  components: {
    DialogLogin,
  },

  data: () => ({
    cardTop: 0,
    appBarHeight: 0,

    dialogLogin: false,

    showContent: false,
    isEnglish: true,

    group: null,

    itemsNoLogin: [],
    itemsLogin: [],
    hoveredItem: null,

    userData: {},
  }),

  watch: {
    "$i18n.locale"(val) {
      if (val) {
        this.itemsNoLogin = [
          { title: this.$t("appbar.register") },
          { title: this.$t("appbar.login1") },
        ];
        this.itemsLogin = [
          { title: this.$t("appbar.profile") },
          { title: this.$t("appbar.mywallet") },
          { title: this.$t("appbar.purchasehistory") },
          { title: this.$t("appbar.logout") },
        ];
      }
    },
  },

  created() {
    const encodedUserLogin = localStorage.getItem("TaweechokUser");
    if (encodedUserLogin !== null) {
      const decodedUserLogin = Decode.decode(encodedUserLogin);
      this.userData = JSON.parse(decodedUserLogin);
    }

    this.itemsNoLogin = [
      { title: this.$t("appbar.register") },
      { title: this.$t("appbar.login1") },
    ];
    this.itemsLogin = [
      { title: this.$t("appbar.profile") },
      { title: this.$t("appbar.mywallet") },
      { title: this.$t("appbar.purchasehistory") },
      { title: this.$t("appbar.logout") },
    ];
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    goToLineLogin() {
      const lineLoginChannelId = "2005547411";
      const redirectUrl = `https://xn--42cl8aag0fln1dc5fi3lwa4h.com/lineLogin`;
      const scope = "profile%20openid%20email";
      const state = "12w3xt3x";
      const lineLoginUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${lineLoginChannelId}&redirect_uri=${redirectUrl}&scope=${scope}&state=${state}`;
      window.location.href = lineLoginUrl;
    },
    handleScroll() {
      const scrollY = window.scrollY || window.pageYOffset;
      const appBarHeight = 0; // Adjust this value based on your app bar height
      const cardRef = this.$refs.stickyCard;

      if (scrollY > appBarHeight) {
        this.cardTop = scrollY;
      } else {
        this.cardTop = appBarHeight;
      }
    },
    async logout() {
      localStorage.removeItem("TaweechokUser");
      localStorage.clear();
      this.$router.currentRoute.path == "/"
        ? this.$router.push("/")
        : this.$router
            .push({
              path: "/",
            })
            .then(() => {
              this.$router.go(0);
            });
    },
    goToAnotherPage(item) {
      if (item == "ตู้เซฟ") {
        Object.keys(this.userData).length !== 0
          ? this.$router.currentRoute.path == "/safe"
            ? ""
            : this.$router.push({
                path: "/safe",
              })
          : (this.dialogLogin = true);
      }
      if (item == "ตะกร้า") {
        Object.keys(this.userData).length !== 0
          ? this.$router.currentRoute.path == "/basket"
            ? ""
            : this.$router.push({
                path: "/basket",
              })
          : (this.dialogLogin = true);
      }
      if (item == "ติดต่อเจ้าหน้าที่") {
        window.open("https://line.me/ti/p/~naphat.p888", "_blank");
      }
      if (item == "นโยบายและเงื่อนไข") {
        Object.keys(this.userData).length !== 0
          ? this.$router.currentRoute.path == "/profile"
            ? ""
            : this.$router.push({
                path: "/profile",
              })
          : (this.dialogLogin = true);
      }
      if (item == "ความเป็นส่วนตัว") {
        Object.keys(this.userData).length !== 0
          ? this.$router.currentRoute.path == "/profile"
            ? ""
            : this.$router.push({
                path: "/profile",
              })
          : (this.dialogLogin = true);
      }
      if (item == "เกี่ยวกับเรา") {
        window.open("https://line.me/ti/p/~naphat.p888", "_blank");
      }
      if (item === "ออกจากระบบ") {
        this.logout();
      }

      this.group = null;
      this.showContent = false;
    },
    closeDialogLogin() {
      this.dialogLogin = false;
    },

    switchLanguage() {
      this.$i18n.locale = this.$i18n.locale == "th" ? "en" : "th";
    },

    showContentToggle() {
      this.showContent = !this.showContent;

      document.body.addEventListener("click", this.handleClickOutside);
    },
    handleClickOutside(event) {
      // Check if the clicked element is not within the specified HTML element
      if (!this.$el.contains(event.target)) {
        this.showContent = false;
        document.body.removeEventListener("click", this.handleClickOutside);
      }
    },
    setHoveredItem(index) {
      this.hoveredItem = index;
    },
    resetHoveredItem() {
      this.hoveredItem = null;
    },

    handleItemClick(item) {
      if (item.title === "ลงชื่อเข้าใช้" || item.title === "Login") {
        this.dialogLogin = true;
      }
      if (item.title === "สมัครสมาชิก" || item.title === "Register") {
        this.group = null;

        this.goToLineLogin();
        // this.$router.currentRoute.path == "/register"
        //   ? ""
        //   : this.$router.push({
        //       path: "/register",
        //     });
      }

      /// already login
      if (item.title === "ข้อมูลส่วนตัว" || item.title === "Profile") {
        this.group = null;

        this.$router.currentRoute.path == "/profile"
          ? ""
          : this.$router.push({
              path: "/profile",
            });
      }
      if (item.title === "ตู้เซฟ" || item.title === "Safe box") {
        this.group = null;

        this.$router.currentRoute.path == "/safe"
          ? ""
          : this.$router.push({
              path: "/safe",
            });
      }
      if (item.title === "คำสั่งซื้อ" || item.title === "Order") {
        this.group = null;

        this.$router.currentRoute.path == "/basket"
          ? ""
          : this.$router.push({
              path: "/basket",
            });
      }
      if (item.title === "ออกจากระบบ") {
        this.logout();

        this.$router.currentRoute.path == "/"
          ? ""
          : this.$router.push({
              path: "/",
            });

        this.$router.go(0);
      }
    },
  },
};
</script>

<style scoped>
.selected-item {
  background-color: #718096;
}

.sticky-card {
  position: fixed;
  width: 100%;
  z-index: 9999;
  transition: top 0.3s ease-out;
}

/* .theme--light.v-list {
  background: #2e2e2e;
} */

/* .theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: white;
} */

.responsive-img {
  max-width: 50%;
  height: auto;
  border: 1px solid rgb(219, 193, 103);
  border-radius: 50%;
}

.hovered-item {
  background-color: rgb(219, 193, 103);
}

.v-list-item__title,
.v-list-item__subtitle {
  overflow: visible;
}

.responsive-col {
  width: 100%; /* Default to full width */
}

.responsive-item-app-bar {
  margin-top: 20px;
}

@media (max-width: 600px) {
  .responsive-col {
    width: 50%; /* Take up 50% width on screens wider than 600px */
  }

  .responsive-item-app-bar {
    margin-top: 100px;
  }
}

@media (max-width: 960px) {
  .responsive-col {
    width: 33.33%; /* Take up 33.33% width on screens wider than 960px */
  }

  .responsive-item-app-bar {
    margin-top: 30px;
  }
}
</style>
